$(document).ready(function() {
    var hWin = $(window).height();
    var wWin = $(window).width();
    var hHeader = $('.home .main-header').outerHeight(true);
    console.log(hHeader);
    var hBanner = hWin - hHeader;
    $('.home .banner').height(hBanner);
    ////////////////////////
    var hFooter = $('.home .footer').height();
    var hMap = hWin - hFooter;
    $('.home .group-map').height(hMap - 45);
    $('.home .group-map iframe').height(hMap - 45 - 80);
    ////////////////////////
    if (wWin < hWin) {
        $('.banner .pc-js').addClass('hide');
        $('.banner .mobi-js').removeClass('hide');
    } else {
        $('.banner .pc-js').removeClass('hide');
        $('.banner .mobi-js').addClass('hide');
    }
});
$(window).resize(function() {
    var hWin = $(window).height();
    var wWin = $(window).width();
    var hHeader = $('.home .main-header').outerHeight(true);
    console.log(hHeader);
    var hBanner = hWin - hHeader;
    $('.home .banner').height(hBanner);
    ////////////////////////
    var hFooter = $('.home .footer').height();
    var hMap = hWin - hFooter;
    $('.home .group-map').height(hMap - 45);
    $('.home .group-map iframe').height(hMap - 45 - 80);
    ////////////////////////
    if (wWin < hWin) {
        $('.banner .pc-js').addClass('hide');
        $('.banner .mobi-js').removeClass('hide');
    } else {
        $('.banner .pc-js').removeClass('hide');
        $('.banner .mobi-js').addClass('hide');
    }
});